/* eslint no-bitwise: 0 */
import { Bbox, MapCoordindates, QuadkeyTile, Tile } from '../../types/weather';

const MinLatitude = -85.05112878;
const MaxLatitude = 85.05112878;
const MinLongitude = -180.0;
const MaxLongitude = 180.0;

/**
 * clips a number by a minimum and maximum value
 * @param  {number} n        the number to clip
 * @param  {number} minValue minimum value, if n is less this will return
 * @param  {number} maxValue maximum value, if n is greater than this will return
 * @return {number}          value of n clipped to be >= minValue and <= maxValue
 */
export const clip = (n, minValue: number, maxValue: number): number => {
    return Math.min(Math.max(n, minValue), maxValue);
};

/**
 * get the size of the map in pixels for a specified detail level
 * @param  {string} detail map detail level
 * @return {number} size of the map in pixels for the given detail level
 */
export const mapSize = (detail: string): number => {
    return 256 << parseInt(detail, 10);
};

/**
 * convert pixel coordinates to tile coordinates
 * @param  {xycoord} pixel pixel coordinates
 * @return {xycoord}       tile coordinates
 */
export const pixelToTile = (pixel: QuadkeyTile): QuadkeyTile => {
    return {
        x: pixel.x / 256,
        y: pixel.y / 256
    };
};

/**
 * convert tile coordinates to pixel coordinates
 * @param  {xycoord} tile tile coordinates
 * @return {xycoord}      pixel coordinates
 */
export const tileToPixel = (tile: Tile) => {
    return {
        x: tile.x * 256,
        y: tile.y * 256
    };
};

/**
 * convert a pixel coordinate to a location at a specific map detail
 * @param  {xycoord}  pixel  the pixel coordinate to convert
 * @param  {number}   detail detail level of map to use
 * @return {geocoord} location value for the input pixel coordinate at the input detail level
 */
export const pixelToLocation = (
    pixel: QuadkeyTile,
    detail: string
): MapCoordindates => {
    const size = parseFloat(String(mapSize(detail)));
    const x = clip(parseFloat(String(pixel.x)), 0, size - 1.0) / size - 0.5;
    const y = 0.5 - clip(parseFloat(String(pixel.y)), 0, size - 1.0) / size;
    const lat =
        90.0 -
        (360.0 * Math.atan(Math.exp(-1.0 * y * 2.0 * Math.PI))) / Math.PI;
    const lng = 360.0 * x;
    return {
        lat: lat,
        lng: lng
    };
};

/**
 * convert tile coordinates to location at specific detail level, this will be the lat,lng of the tile corner
 * @param  {xycoord}  tile   tile coordinates
 * @param  {number}   detail map detail level used in the conversion
 * @return {geocoord}        location of tile at input detail level
 */
export const tileToLocation = (tile: Tile, detail: string): MapCoordindates => {
    const pixel = tileToPixel(tile);
    return pixelToLocation(pixel, detail);
};

/**
 * convert a location to a pixel value for a specific map detail level
 * @param  {geocoord} coordinates to convert to pixel location
 * @param  {number} detail detail level of map to use in the conversion
 * @return {xycoord} pixel value for the input location at the input detail level
 */
export const locationToPixel = (
    coord: MapCoordindates,
    detail: number
): QuadkeyTile => {
    const lat = clip(coord.lat, MinLatitude, MaxLatitude);
    const lng = clip(coord.lng, MinLongitude, MaxLongitude);
    const x = (lng + 180.0) / 360.0;
    const sinLat = Math.sin((lat * Math.PI) / 180.0);
    const y = 0.5 - Math.log((1.0 + sinLat) / (1.0 - sinLat)) / (4.0 * Math.PI);
    const size = parseFloat(String(mapSize(String(detail))));
    const pixelX = parseInt(String(clip(x * size + 0.5, 0, size - 1.0)), 10);
    const pixelY = parseInt(String(clip(y * size + 0.5, 0, size - 1.0)), 10);

    return {
        x: pixelX,
        y: pixelY
    };
};

/**
 * convert tile coordinates to quadkey at specific detail level
 * @param  {xycoord} tile coordinates
 * @param  {number} map detail level to use for conversion
 * @return {string} for input tile coordinates at input detail level
 */
export const tileToQuadkey = (tile: Tile, detail: number): string => {
    let out = '';
    for (let i = detail; i > 0; i--) {
        const digit = '0';
        let value = digit.charCodeAt(0);
        const mask = 1 << (i - 1);

        if ((tile.x & mask) !== 0) {
            value++;
        }
        if ((tile.y & mask) !== 0) {
            value++;
            value++;
        }

        out += String.fromCharCode(value);
    }
    return out;
};

/**
 * convert quadkey to tile coordinates, detail level can be inferred from the length of
 * the quadkey string.
 * @param  {string}  quadkey quadkey to be converted
 * @return {xycoord}         tile coordinates
 */
export const quadkeyToTile = (quadkey: string): QuadkeyTile => {
    let tileX = 0;
    let tileY = 0;
    const detail = quadkey.length;
    for (let i = detail; i > 0; i--) {
        const mask = 1 << (i - 1);
        const index = detail - i;
        switch (quadkey[index]) {
            case '0':
                break;
            case '1':
                tileX |= mask;
                break;
            case '2':
                tileY |= mask;
                break;
            case '3':
                tileX |= mask;
                tileY |= mask;
                break;
            default:
                throw new Error('Invalid quadkey');
        }
    }
    return {
        x: tileX,
        y: tileY
    };
};

/**
 * get quadkey for location at specific detail level
 * @param  {geocoord} location location coordinates to convert to quadkey
 * @param  {number} detail map detail level of quadkey to return
 * @return {string} quadkey the input location resides in for the input detail level
 */
export const locationToQuadkey = (
    location: MapCoordindates,
    detail: number
): string => {
    const pixel = locationToPixel(location, detail);
    const tile = pixelToTile(pixel);
    return tileToQuadkey(tile, detail);
};

/**
 * get the bounding box for a quadkey in location coordinates
 * @param  {string} quadkey quadkey to get bounding box of
 * @return {bbox} bounding box for the input quadkey
 */
export const bbox = (quadkey: string): Bbox => {
    const tile = quadkeyToTile(quadkey);
    const nextTile = { x: tile.x + 1, y: tile.y + 1 };
    const detail = String(quadkey.length);
    const first = tileToLocation(tile, detail);
    const second = tileToLocation(nextTile, detail);
    const minCoord = {
        lat: Math.min(first.lat, second.lat),
        lng: Math.min(first.lng, second.lng)
    };
    const maxCoord = {
        lat: Math.max(first.lat, second.lat),
        lng: Math.max(first.lng, second.lng)
    };
    return {
        max: maxCoord,
        min: minCoord
    };
};

export const getTiles = (
    centerQuadkey: string,
    width: number,
    height: number,
    level: number
): Tile[] => {
    const tiles = [];
    const centerTile = quadkeyToTile(centerQuadkey);
    const x = Math.ceil(width / 2 / 256);
    const y = Math.ceil(height / 2 / 256);

    for (let j = -y; j <= y; j++) {
        for (let i = -x; i <= x; i++) {
            const tile = {
                offsetX: 0,
                offsetY: 0,
                quadkey: '0',
                x: centerTile.x + i,
                y: centerTile.y + j
            };
            tile.offsetX = 256 * i;
            tile.offsetY = 256 * j;
            tile.quadkey = tileToQuadkey(tile, level);
            tiles.push(tile);
        }
    }

    return tiles;
};

export const getUTCDate = (): string => {
    var d = new Date();
    const round = 15; // mins;
    return [
        [d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDate()].join('-'),
        [
            d.getUTCHours(),
            Math.floor(d.getUTCMinutes() / round) * round,
            0
        ].join(':')
    ].join('T');
};
