/*global globalThis*/
import { locationToQuadkey, bbox, getTiles, getUTCDate } from './quadkey';
import { rapidBeaconClick } from './utils/rapid';

const { wafer } = window;

declare global {
    interface Window {
        YMaps: any;
    }
}

function getWeatherMapOverlayOpacity() {
    const opacityObj = {
        global_ir_satellite_10km: 1,
        synoptic_temp: 0.6,
        wind_day_m_0: 0.4
    };

    return opacityObj;
}

function getWeatherMapCoordinates() {
    const weatherMapEl = document.getElementById('weather-map');
    const latitude = parseFloat(weatherMapEl.getAttribute('data-latitude'));
    const longitude = parseFloat(weatherMapEl.getAttribute('data-longitude'));

    return {
        latitude,
        longitude,
        weatherMapEl
    };
}

function getWeatherMapOverlayData() {
    const { latitude, longitude, weatherMapEl } = getWeatherMapCoordinates();
    const mapWidth = weatherMapEl.offsetWidth;
    const mapHeight = weatherMapEl.offsetHeight;
    const quadkey = locationToQuadkey({ lat: latitude, lng: longitude }, 5);
    const tiles = getTiles(quadkey, mapWidth, mapHeight, 5);
    const box = bbox(quadkey) as any;
    const offsetX =
        (256 * (box.min.lng - longitude)) / (box.max.lng - box.min.lng);
    const offsetY =
        (256 * (latitude - box.max.lat)) / (box.max.lat - box.min.lat);
    const mapOffsetX = mapWidth / 2 + offsetX;
    const mapOffsetY = mapHeight / 2 + offsetY;
    const date = getUTCDate();

    return {
        date,
        mapOffsetX,
        mapOffsetY,
        tiles
    };
}

function getWeatherMapTileMarkup(
    date,
    tile,
    offsetX,
    offsetY,
    opacity,
    type
): HTMLImageElement {
    const quadkey = tile.quadkey;
    const top = offsetY + tile.offsetY;
    const left = offsetX + tile.offsetX;
    const imgEl = document.createElement('img') as HTMLImageElement;
    imgEl.src = `https://weather-flickr-yql-tile.media.yahoo.com/v3/weather_tile/${date}/${type}/${quadkey}.png`;
    imgEl.style.top = `${top}px`;
    imgEl.style.left = `${left}px`;
    imgEl.style.opacity = opacity;
    imgEl.className = 'Pos(a)';
    return imgEl;
}

function renderWeatherMapTiles(
    type = 'global_ir_satellite_10km',
    opacity = ''
) {
    globalThis.weatherMapOverlayType = type;
    if (!opacity) {
        opacity = getWeatherMapOverlayOpacity()[type];
    }
    const { date, tiles, mapOffsetX, mapOffsetY } = getWeatherMapOverlayData();
    const mapOverlayEl = document.getElementById('weather-map-overlay');
    mapOverlayEl.innerHTML = '';
    if (tiles) {
        for (let i = 0; i < tiles.length; i++) {
            mapOverlayEl.appendChild(
                getWeatherMapTileMarkup(
                    date,
                    tiles[i],
                    mapOffsetX,
                    mapOffsetY,
                    opacity,
                    type
                )
            );
        }
    }
}

function weatherMapButtonsOpacityToggle(el) {
    const weatherButtons =
        document.getElementsByClassName('weather-map-toggle');
    for (const button of weatherButtons) {
        button.classList.remove('Op(1)');
        button.classList.add('Op(.6)');
    }
    el.classList.add('Op(1)');
}

let weatherMap;
function renderWeatherMap() {
    if (weatherMap) {
        weatherMap.destroy;
    }
    const mapWidth = document.getElementById('weather-map').offsetWidth;
    const YahooMaps = window.YMaps;
    const { latitude, longitude } = getWeatherMapCoordinates();
    const yahooGeo = new YahooMaps.GeoPoint(latitude, longitude);
    weatherMap = new YahooMaps.Map(
        document.querySelector('.map-canvas'),
        {
            center: yahooGeo,
            height: 305,
            tileType: YahooMaps.MapTileModel.satellite,
            width: mapWidth,
            zoom: 5
        },
        {
            brandControl: false,
            copyright: false,
            enableMouse: false,
            fps: false,
            mapTypeToggle: false,
            preloadTiles: false,
            scale: false,
            zoomControl: false
        }
    );
    weatherMap.draw(
        new YahooMaps.Marker({
            geo: yahooGeo
        })
    );
}

function weatherHnadleWindowResize() {
    renderWeatherMap();
    const overlayType = globalThis.weatherMapOverlayType;
    const opacity = getWeatherMapOverlayOpacity()[overlayType];
    renderWeatherMapTiles(globalThis.weatherMapOverlayType, opacity);
}

wafer.ready(() => {
    wafer.on('resize', weatherHnadleWindowResize);
    wafer.on('autocomplete:select', e => {
        rapidBeaconClick(e);
    });
    wafer.on('autocomplete:deleted', rapidBeaconClick);
}, window);

document
    .getElementById('weather-map-toggle-satellite')
    .addEventListener('click', function () {
        renderWeatherMapTiles();
        weatherMapButtonsOpacityToggle(this);
    });
document
    .getElementById('weather-map-toggle-heat')
    .addEventListener('click', function () {
        renderWeatherMapTiles('synoptic_temp');
        weatherMapButtonsOpacityToggle(this);
    });
document
    .getElementById('weather-map-toggle-wind')
    .addEventListener('click', function () {
        renderWeatherMapTiles('wind_day_m_0');
        weatherMapButtonsOpacityToggle(this);
    });

renderWeatherMap();
renderWeatherMapTiles();
