/**
 * Handle wafer beacon click
 * @param {object} eventData event data
 * @return {void}
 */
export const rapidBeaconClick = eventData => {
    const rapidInstance = window.rapidInstance;
    const { meta } = eventData;
    const { index, selectedValue, type, ylk } = meta;
    const { sec, slk = selectedValue, ...otherClickParams } = ylk;

    if (!otherClickParams.elm) {
        otherClickParams.elm = 'btn';
    }
    rapidInstance.beaconClick(
        sec,
        slk,
        index,
        {
            ...otherClickParams,
            elmt: type
        },
        ''
    );
};
